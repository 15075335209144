<template>
  <section class="card product-section" v-if="productCount > 0" :id="'category' + data.id">
    <div class="section-title">
      <span class="title">{{data.title}}</span>
      <p v-if="data.description">{{data.description}}</p>
      <div class="availability" v-if="data.availability">
        <i class="material-icons notranslate">info_outline</i>
        <small v-if="data.availability">
          {{ data.availability.text }}
        </small>
      </div>
    </div>
    <div class="product-item" :class="{'product-item-disabled': !product.avaible}" v-for="product in data.products" :arial-id="product.id" :key="product.id" @click="openProduct(product)"
         v-if="cart.type === 'on_site' && product.onsite === true || cart.type === 'pickup' && product.pickup === true || cart.type === 'on_site_counter' && product.onsite === true || cart.type === 'delivery' && product.delivery === true">
      <div class="infos">
        <strong class="name">{{ product.name }}</strong>
        <p>{{ product.descriptionShort }}</p>
        <div class="priceAndStar">
          <span class="price" v-if="cart.type === 'on_site' || cart.type === 'on_site_counter'">{{ product.priceOnsite.price }} <small v-if="!product.avaible">Indisponible</small></span>
          <span class="price" v-if="cart.type === 'pickup'">{{ product.pricePickup.price }} <small v-if="!product.avaible">Indisponible</small></span>
          <span class="price" v-if="cart.type === 'delivery'">{{ product.priceDelivery.price }} <small v-if="!product.avaible">Indisponible</small></span>
        </div>
      </div>
      <div v-if="product.imageSmall.path" style="position: relative;">
        <img :src="product.imageSmall.path">
        <div style="position: absolute; top:0; padding: 5px 0 0 25px;">
          <i v-if="product.highlighted && product.avaible" class="material-icons star">star</i>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { mapGetters } from 'vuex';
import {$vfm} from "vue-final-modal";
import AddProduct from "@/components/Modals/Product/AddProduct";

export default {
  props: ['category'],
  data(){
    return {
      data: false
    }
  },
  methods: {
    openProduct(product){
      if(this.merchant.activated === false){
        this.$root.$emit('openOrderTime', {initiated: false});
        return;
      }
      $vfm.show({component: AddProduct, bind: {product: JSON.parse(JSON.stringify(product))}});
      window.history.pushState({}, "",location.pathname + '#product');
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant']),
    productCount(){
      if(!this.data){
        return 0;
      }
      if(this.cart.type === 'delivery'){
        return this.data.products.filter(p => p.delivery === true).length;
      }
      else if(this.cart.type === 'pickup'){
        return this.data.products.filter(p => p.pickup === true).length;
      }
      else if(this.cart.type === 'on_site'){
        return this.data.products.filter(p => p.onsite === true).length;
      }
      else if(this.cart.type === 'on_site_counter'){
        return this.data.products.filter(p => p.onsite === true).length;
      }
    }
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.category));
  }
}
</script>
