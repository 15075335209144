<template>
  <section class="product-section-mono-cat" v-if="productCount > 0" :id="'category' + data.id">
    <div class="section-title">
      <div class="title">{{data.title}}</div>
      <div class="description" v-if="data.description">{{data.description}}</div>
      <div class="availability" v-if="data.availability">
        <i class="material-icons notranslate">info_outline</i>
        <small v-if="data.availability">
          {{ data.availability.text }}
        </small>
      </div>
    </div>
    <div
      class="products"
    >
      <div
        class="product-item"
        :class="{'product-item-disabled': !product.avaible}"
        v-for="product in data.products"
        :arial-id="product.id"
        :key="product.id"
        v-if="cart.type === 'on_site' && product.onsite === true || cart.type === 'pickup' && product.pickup === true || cart.type === 'on_site_counter' && product.onsite === true || cart.type === 'delivery' && product.delivery === true"
        @click="openProduct(product)"
      >
        <div v-if="product.highlighted" class="star">
          <i class="material-icons">star</i>
          <span>Produit star</span>
        </div>
        <img
          :src="product.imageLarge.path ? product.imageLarge.path : merchant.logo.path"
          :style="!product.imageLarge.path ? 'filter: brightness(70%);' : ''"
        >
        <div class="infos">
          <div class="card_title">
            <strong class="name">{{ product.name }}</strong>
            <p>{{ product.descriptionShort }}</p>
          </div>
          <div class="card_footer">
            <span class="price" v-if="cart.type === 'on_site' || cart.type === 'on_site_counter'">{{ product.priceOnsite.price }}</span>
            <span class="price" v-if="cart.type === 'pickup'">{{ product.pricePickup.price }}</span>
            <span class="price" v-if="cart.type === 'delivery'">{{ product.priceDelivery.price }}</span>
            <small v-if="!product.avaible">Indisponible</small>
            <i v-if="product.avaible" class="material-icons">arrow_forward</i>
          </div>
        </div>
      </div>

    </div>

  </section>

</template>

<script>
import { mapGetters } from 'vuex';
import {$vfm} from "vue-final-modal";
import AddProduct from "@/components/Modals/Product/AddProduct";

export default {
  props: ['category'],
  data(){
    return {
      data: false
    }
  },
  methods: {
    openProduct(product){
      if(this.merchant.activated === false){
        this.$root.$emit('openOrderTime', {initiated: false});
        return;
      }
      $vfm.show({component: AddProduct, bind: {product: JSON.parse(JSON.stringify(product))}});
      window.history.pushState({}, "",location.pathname + '#product');
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant']),
    productCount(){
      if(!this.data){
        return 0;
      }
      if(this.cart.type === 'delivery'){
        return this.data.products.filter(p => p.delivery === true).length;
      }
      else if(this.cart.type === 'pickup'){
        return this.data.products.filter(p => p.pickup === true).length;
      }
      else if(this.cart.type === 'on_site'){
        return this.data.products.filter(p => p.onsite === true).length;
      }
      else if(this.cart.type === 'on_site_counter'){
        return this.data.products.filter(p => p.onsite === true).length;
      }
    }
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.category));
  }
}
</script>
