<template>
  <div class="merchant-footer mt-5">
    <div class="merchant-footer__image">
      <div class="image"><img :src="merchant.logo.path" alt=""></div>
    </div>
    <div class="merchant-footer__content">
      <div class="merchant-name">{{merchant.name}}</div>
      <div class="merchant-address">{{merchant.address.address}}, {{merchant.address.zip}} {{merchant.address.city}} <template v-if="merchant.phone">• {{merchant.phone}}</template></div>
      <div class="merchant-links">
        <a href="#" @click.prevent="$root.$emit('openDeliveryInfo')">Frais de livraison</a>•<a href="https://merchant.delicity.com" target="_blank">Connexion pro</a>
      </div>
      <div class="merchant-social">

        <a :href="s.url" v-for="s in merchant.social" target="_blank" class="social-item">
          <img :src="s.logo.path" alt="">
        </a>

      </div>
    </div>
    <div class="merchant-footer__bottom">
      <div class="container">
        <div class="bottom-footer">
          <a href="https://delicity.com" target="_blank"><img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg" height="20px"></a>
          <span class="copy">&copy; 2020-{{currentYear}} · <a href="https://pro.delicity.com/cgv/" target="_blank">CGV</a> · <a href="https://pro.delicity.com/confidentialite/" target="_blank">Politique de confidentialité</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed:{
    ...mapGetters(['merchant']),
    currentYear(){
      return new Date().getFullYear();
    }
  }
}
</script>
