<template>
  <div class="container">
    <div class="merchant-header">
      <div class="merchant-banner" :style="{'background-image': 'url('+merchant.backgroundImages+')'}">
      </div>
      <app-order-config></app-order-config>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import OrderConfig from "@/desktopComponents/Merchant/OrderConfig";
import MerchantUnavailableOrders from "@/components/MerchantUnavailableOrders";

export default {
  components:{
    'app-order-config': OrderConfig,
    'app-merchant-unavailable-orders': MerchantUnavailableOrders
  },
  computed: {
    ...mapGetters(['merchant'])
  }
}
</script>
