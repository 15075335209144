<template>
  <div class="container category-container" :class="{'pt-2': !cart.deliveryAvailable && cart.deliveryClosedReason}">
    <app-announcement position="top_product" class="mb-4"></app-announcement>
    <slot name="top"></slot>
    <div>
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
import Announcement from "@/mobileComponents/Merchant/Announcement";
import {mapGetters} from "vuex";

export default {
  components: {
    'app-announcement': Announcement,
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
