<template>
  <div>
    <router-link :to="{name: 'checkout', params: {orderToken: user.currentOrder.token, merchantSlug: user.currentOrder.merchantSlug}}" class="merchant-current-order" v-if="user.order">
      <span>Une commande en cours</span>
      <i class="material-icons notranslate">arrow_forward</i>
    </router-link>

    <div class="cart-floating-button-container"  v-if="!user.order && cart && cart.values.total.value !== 0 && cart.quantity !== 0">
      <div style="padding: 20px">
        <ui-button color="green" @click="openCart" size="large" class="cart-floating-button">
          <span>Valider la commande</span>
          <span>{{cart.values.totalProduct.price}}</span>
        </ui-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    openCart(){
      this.$root.$emit('openCart');
    }
  },
  computed: {
    ...mapGetters(['cart', 'user'])
  }
}
</script>
