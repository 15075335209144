<template>
  <div class="order-closed" v-if="merchant && merchant.shiftsOnSite.open && !merchant.shifts.open">
    <template v-if="!exceptional && !merchant.shifts.open && merchant.shifts.openAt === null">
      <i class="material-icons notranslate">phonelink_erase</i>
      <span>{{ $t('order_closed.header_closed') }}</span>
    </template>
    <template v-else-if="!merchant.shifts.open && merchant.shifts.openAt !== null">
      <i class="material-icons notranslate">redo</i>
      <span>{{ $t('order_closed.header_closed_until', {time:merchant.shifts.openAt}) }}</span>
    </template>
  </div>
</template>

<style lang="scss">
.order-closed
{
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 0.9em;
  text-align: center;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  i
  {
    font-size: 1.2em;
    margin-right: 8px;
  }
}
</style>


<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['merchant'])
  },
  mounted() {
    console.log(this.merchant);
  }
}
</script>
