<template>
  <router-link :to="{name: 'checkout', params: {orderToken: user.currentOrder.token, merchantSlug: user.currentOrder.merchantSlug}}"
               class="merchant-current-order"
               v-if="user.currentOrder && merchant.slug === user.currentOrder.merchantSlug">
    <span>{{ $t('go_on_current_order') }}</span>
    <i class="material-icons notranslate animate__animated animate__fadeInLeft animate__infinite">arrow_forward</i>
  </router-link>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  computed: {
    ...mapGetters(['user', 'merchant'])
  }
}
</script>
