<template>
  <vue-final-modal name="add-product" v-bind="$attrs" v-slot="{ close }"
    :classes="['vfm-container vfm-product', product.imageLarge.path ? 'vfm-product__with-image' : '']" v-on="$listeners" content-class="modalBoxShadow">
    <div class="product_container scroll">
      <div class="product_image" v-if="product.imageLarge.path"
        :style="'background-image: url(' + product.imageLarge.path + ')'">
        <button class="product_image_back d-flex d-lg-none" @click="close"><i
            class="material-icons">arrow_back</i></button>
      </div>
      <div class="product_content">
        <div class="product_content_data scroll">

          <div class="insidePadding">

            <button class="product_image_back d-flex d-lg-none d-flex d-lg-none mb-1 justify-content-start px-0"
              v-if="!product.imageLarge.path" @click="close"><i class="material-icons">arrow_back</i></button>
            <div class="data__header">
              <h4 class="font-weight-bold">{{ product.name }}</h4>
              <button @click="close" class="d-none d-lg-flex"><i class="material-icons">close</i></button>
            </div>
            <div v-if="cart.type === 'on_site'" class="mb-1 font-weight-bold">{{ totalProductPrice }}</div>
            <span class="description">{{ product.description }}</span>

          </div>

          <div class="productLine"></div>

          <product-options ref="optionSelector" :product="product" v-model="selectedOptions" />

          <div class="product-comment insidePadding" v-if="cart.type !== 'on_site'">
            <label>Instructions spécifiques</label>
            <textarea v-model="comment"
              placeholder="Ajoutez vos allergies ou vos commentaires (plus de sauce, pas d'oignon...)"
              class="form-control"></textarea>
            <small>Si vous souffrez d'allergies alimentaires ou si vous suivez un régime alimentaire spécifique, veuillez
              prendre contact directement avec le restaurant avant de passer votre commande.</small>
          </div>

        </div>
        <div class="product_content_footer d-none d-lg-flex">

          <div class="product-quantity" v-if="cart.type !== 'on_site'">
            <button @click="quantity--" :disabled="quantity === 1"><i class="material-icons">remove</i></button>
            <span>{{ quantity }}</span>
            <button @click="quantity++"><i class="material-icons">add</i></button>
          </div>

          <button class="product_cta product_cta_blue" style="gap:0;" @click="openCartTime"
            v-if="showAvailabilityChanger && cart.type !== 'on_site'">
            <div style="font-size: 80%;">Changer date de la commande</div>
            <small style="font-size: 67%;">Dispo. uniquement {{ category.availability.text }}</small>
          </button>

          <button class="product_cta" :class="{ 'product_cta_disabled': !product.avaible }" @click="addToCartButton"
            :disabled="isLoading || !product.avaible" v-else-if="cart.type !== 'on_site'">
            <loader v-if="isLoading"></loader>
            <div class="addToCart" v-if="!isLoading && product.avaible">Ajouter au panier</div>
            <div class="addToCart" v-if="!isLoading && !product.avaible">Indisponible</div>
            <div v-if="!isLoading">{{ totalProductPrice }}</div>
          </button>

        </div>
      </div>
    </div>
    <div class="product_content_footer d-lg-none">

      <div class="product-quantity" v-if="cart.type !== 'on_site'">
        <button @click="quantity--" :disabled="quantity === 1"><i class="material-icons">remove</i></button>
        <span>{{ quantity }}</span>
        <button @click="quantity++"><i class="material-icons">add</i></button>
      </div>

      <button class="product_cta product_cta_blue" @click="openCartTime"
        v-if="showAvailabilityChanger && cart.type !== 'on_site'">
        <span>Changer de date pour ajouter au panier</span>
      </button>

      <button class="product_cta" :class="{ 'product_cta_disabled': !product.avaible }" @click="addToCartButton"
        :disabled="isLoading || !product.avaible" v-else-if="cart.type !== 'on_site'">
        <loader v-if="isLoading"></loader>
        <div class="addToCart" v-if="!isLoading && product.avaible">Ajouter au panier</div>
        <div class="addToCart" v-if="!isLoading && !product.avaible">Indisponible</div>
        <div v-if="!isLoading">{{ totalProductPrice }}</div>
      </button>

    </div>
  </vue-final-modal>
</template>

<style lang="scss">
.productLine {
  height: 3px;
  width: 100%;
  background-color: #F0F0F0;
}
.insidePadding {
  padding: 20px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { $vfm } from "vue-final-modal";
import ProductOptions from '@/components/Product/ProductOptions.vue';

export default {
  name: 'AddProduct',
  components: { ProductOptions },
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      selectedOptions: [],
      isLoading: false,
      quantity: 1,
      comment: '',
      category: null
    }
  },
  methods: {
    ...mapActions(['addToCart']),

    openCartTime() {
      this.$root.$emit('openOrderTime', { next: this.category.availability.availableTimestamp });
    },

    addToCartButton() {
      if (this.$refs.optionSelector.validateFields()) {
        // Add to cart
        const optionFlat = [];
        this.selectedOptions.forEach(option => {
          option.values.forEach((value) => {
            optionFlat.push({
              optionId: option.id,
              valueId: value.id,
              quantity: value.quantity
            });
          })
        });

        let availability = false;


        if (this.cart.OrderProducts.find(op => op.categoryId !== this.category.id && op.categoryExclusive === true)) {
          this.$root.$emit('openExclusiveProactive', { options: this.selectedOptions, product: this.product, quantity: this.quantity, comment: this.comment, category: this.category, changeDate: this.category.availability && this.category.availability.mode === 'uniqueDate' });
          return false;
        }
        else if (this.category.exclusive === true && this.cart.OrderProducts.find(op => op.categoryId !== this.category.id)) {
          this.$root.$emit('openExclusiveReactive', { options: this.selectedOptions, product: this.product, quantity: this.quantity, comment: this.comment, category: this.category, changeDate: this.category.availability && this.category.availability.mode === 'uniqueDate' });
          return;
        }
        else if (this.category.availability
          && this.category.availability.mode === 'uniqueDate'
          && (!this.cart.laterOrder || this.cart.laterOrder && this.cart.laterOrder.dateFormatted !== this.category.availability.availableDate)
        ) {
          this.$root.$emit('openChangeDateCart', { options: this.selectedOptions, product: this.product, quantity: this.quantity, comment: this.comment, category: this.category, changeDate: this.category.availability && this.category.availability.mode === 'uniqueDate' });
          return;
        }

        this.isLoading = true;
        this.addToCart({
          options: optionFlat,
          productId: this.product.id,
          quantity: this.quantity,
          comment: this.comment,
          changeDate: availability && availability.mode === 'uniqueDate'
        })
          .then(() => {
            this.isLoading = false;
            $vfm.hideAll();
            this.$root.$emit('addToCart', { product: this.product, quantity: this.quantity });
          });
      }
      else {
        const errorOptionIds = this.$refs.optionSelector.getOptionErrorIds();
        document.getElementById('option-' + errorOptionIds[0])
          .scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant', 'menu']),
    getCurrentPrice() {

      if (this.cart.type === 'delivery') {
        return this.product.priceDelivery;
      }
      else if (this.cart.type === 'pickup') {
        return this.product.pricePickup;
      }
      else if (this.cart.type === 'on_site' || this.cart.type === 'on_site_counter') {
        return this.product.priceOnsite;
      }
      console.log(this.cart.type + ' not found');
      return this.product.priceOnsite;
    },
    showAvailabilityChanger() {
      return this.category && this.category.availability && !this.category.availability.available;
    },
    totalProductPrice() {
      const optionTotalValues = this.selectedOptions.reduce((bigsum, o) => bigsum + o.values.reduce((sum, v) => sum + v.price * v.quantity, 0), 0);
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
        (optionTotalValues + this.getCurrentPrice.value) * this.quantity / 100
      )
    }
  },
  watch: {
    menu() {
      this.category = this.menu.find(m => m.id === this.product.categoryId);
    }
  },
  mounted() {
    this.category = this.menu.find(m => m.id === this.product.categoryId);
    document.addEventListener("backbutton", () => {
      $vfm.hideAll();
    }, false);
  }
}
</script>
